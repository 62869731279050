export default {
    MAX_ATTACHMENT_SIZE: 5000000,

    s3: {
        REGION: "us-east-1",
        BUCKET: "notes-app-uploads-anatolii"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://7moi6yw1rk.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_TdBwNWjSv",
        APP_CLIENT_ID: "7ddhv9c0sildk9nk16dbm93jau",
        IDENTITY_POOL_ID: "us-east-1:466e3c3f-01cc-4085-821f-a4325cac8d38"
    }
};
